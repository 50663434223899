import React, { useState, SetStateAction } from "react"

export default function Dropdown({open, children, onClose}) {
    if(!open) return null
  
    return(
      <>
        
            
              {children}
            
      
          
      </>
    )
  
  }